<template>
  <dialogComp
    :title="dialogType === 'add' ? '有误订单记录新增' : '有误订单记录修改'"
    width="640px"
    :isShow="isShow"
    @close="close"
    @sure="sure"
  >
    <baseInfo :info="info" />
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="115px"
    >
      <el-form-item label="错误类型:" prop="reason">
        <cascader
          :data="reasonList"
          :hval="
            filterArrNull([
              formData.reason,
              formData.reasonOne,
              formData.reasonTwo,
              formData.reasonThree
            ])
          "
          @change="reasonChange"
          placeholder="请选择错误类型"
        />
      </el-form-item>

      <inputFormComp
        label="详细说明:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入详细说明"
      />
      <radioFormComp
        label="处理状态:"
        prop="status"
        :radioList="statusList"
        :hval="formData.status"
        @change="statusChange"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import baseInfo from '../comp/baseInfo'
import { dictThree, errorOrderAdd, errorOrderEdit } from '@/api'
export default {
  components: {
    baseInfo
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      reasonList: [],
      formData: {
        reason: '',
        reasonOne: '',
        reasonTwo: '',
        reasonThree: '',
        remark: '',
        status: 1
      },
      rules: {
        reason: [
          { required: true, message: '请选择错误类型', trigger: 'change' }
        ],
        status: [
          { required: true, message: '请选择原因确认', trigger: 'change' }
        ]
      },
      statusList: [
        { label: '待处理', value: 1 },
        { label: '已处理', value: 2 }
      ]
    }
  },
  mounted () {
    this.getSelectData()
  },
  watch: {
    isShow (val) {
      if (val) {
        if (this.dialogType === 'edit') {
          const {
            reason,
            reasonOne,
            reasonTwo,
            reasonThree,
            status,
            remark
          } = this.info
          this.formData = {
            reason,
            reasonOne,
            reasonTwo,
            reasonThree,
            status,
            remark
          }
        }
      }
    }
  },
  methods: {
    // 获取错误类型
    async getSelectData () {
      const params = {
        code: 101455
      }
      const res = await dictThree(params)
      this.reasonList = res.data || []
    },
    // 封装方法，删掉数组中的null的数据
    filterArrNull (arr) {
      return arr.filter(item => item !== null && item !== '0' && item !== '')
    },
    reasonChange (val) {
      switch (val.length) {
        case 0:
          this.formData.reason = ''
          this.formData.reasonOne = ''
          this.formData.reasonTwo = ''
          this.formData.reasonThree = ''
          break
        case 1:
          this.formData.reason = val[0]
          this.formData.reasonOne = ''
          this.formData.reasonTwo = ''
          this.formData.reasonThree = ''
          break
        case 2:
          this.formData.reason = val[0]
          this.formData.reasonOne = val[1]
          this.formData.reasonTwo = ''
          this.formData.reasonThree = ''
          break
        case 3:
          this.formData.reason = val[0]
          this.formData.reasonOne = val[1]
          this.formData.reasonTwo = val[2]
          this.formData.reasonThree = ''
          break
        case 4:
          this.formData.reason = val[0]
          this.formData.reasonOne = val[1]
          this.formData.reasonTwo = val[2]
          this.formData.reasonThree = val[3]
          break
      }
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    statusChange (val) {
      this.formData.status = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const { orderId, id, orderCode } = this.info
          if (this.dialogType === 'add') {
            const params = {
              orderCode: orderId, // 这是从订单列表取得
              ...this.formData
            }
            errorOrderAdd(params).then(_ => {
              this.$message.success('新增成功')
              this.close()
            })
          } else {
            errorOrderEdit({ ...this.formData, orderCode, id }).then(_ => {
              this.$message.success('修改成功')
              this.close()
            })
          }
        }
      })
    }
  }
}
</script>
