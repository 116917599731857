<template>
  <ul class="base_info">
      <li>
        客户名称：<span>{{ info.customerName }}</span>
      </li>
      <li>
        订单编号：<span>{{ info.orderCode || info.orderId }}</span>
      </li>
      <li>
        订单状态：<span>{{ info.orderStatusName || handleOrderStatus(info.orderStatus) }}</span>
      </li>
      <li>
        在租台数：<span>{{ info.leaseQuantity }}</span>
      </li>
      <li>
        订单商家：<span>{{ info.storeName  || info.companyName || '-'}}</span>
      </li>
      <li v-if="info.rentStartDate">
        订单租期：<span>{{
          `${info.rentStartDate&&info.rentStartDate.slice(0, 10)} ~ ${info.rentEndDate&&info.rentEndDate.slice(
            0,
            10
          )}（${info.period}）`
        }}</span>
      </li>
      <li v-if="info.leaseBeginDate">
        订单租期：<span>{{
          `${handleDate(info.leaseBeginDate)} ~ ${handleDate(info.leaseEndDate)}（${info.period || '-'}）`
        }}</span>
      </li>
      <li style="width:100%">
        商品名称：<span>{{ info.commodityName || info.goodsName}}</span>
      </li>
    </ul>
</template>
<script>
import { formatDate, orderStatus } from '@/utils/common'
export default {
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    handleOrderStatus (val) {
      return orderStatus(val)
    },
    // 处理时间
    handleDate (str) {
      return str ? formatDate(str) : '-'
    }
  }
}
</script>
<style lang="scss" scoped>
  .base_info {
    display: flex;
    flex-wrap: wrap;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
  > li {
    width: 50%;
    margin-bottom: 10px;
    span {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>
